import React from 'react';
import PropTypes from 'prop-types';
import { map, keys } from 'lodash';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

function Documents({
  contract,
  isLoading,
}) {
  const document = doc => (
    <div key={doc.id} style={{ margin: '0.5rem 0.25rem' }}>
      <Link href={doc.url} target="_blank" rel="noopener">{doc.filename}</Link>
    </div>
  );

  const documentSection = category => (
    <div key={category}>
      <Typography variant="h6">{category}</Typography>
      {map(contract.fields[category], doc => document(doc))}
    </div>
  );

  const categories = keys(contract.fields);
  const documentsByCategory = map(categories, category => documentSection(category));

  if (isLoading) return <CircularProgress />;
  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom>Documents</Typography>
      {documentsByCategory}
    </React.Fragment>
  );
}

export default Documents;

Documents.propTypes = {
  contract: PropTypes.shape({}),
  isLoading: PropTypes.bool,
};

Documents.defaultProps = {
  contract: {},
  isLoading: false,
};
