import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import CreatableSelect from 'react-select/lib/Creatable';
import Typography from '@material-ui/core/Typography';
import { RESOURCES } from '../../constants';
import './ResourceSelect.css';

class ResourceSelect extends React.Component {
  handleChange = (selected) => {
    const { childResourceType } = this.props;

    this.props.handleChange({
      resourceType: this.props.resourceType,
      selected,
      childResourceType,
    });
  }

  handleCreate = inputValue => (
    this.props.createResource(inputValue, this.onSuccessfulCreate)
  );

  onSuccessfulCreate = (record) => {
    if (record && record.fields && record.fields.name) {
      const selected = { label: record.fields.name, value: record.id };
      this.handleChange(selected);
    }

    return null;
  }

  options = () => (
    map(this.props.resources, resource => (
      { label: resource.fields.name, value: resource.id }
    ))
  )

  valueFromResources = () => {
    const { value, resources } = this.props;

    const label = value
      && resources[value]
      && resources[value].fields.name;

    return label && { value, label };
  }

  render() {
    const {
      resourceType,
      isLoading,
      isDisabled,
      hasError,
      errorMessage,
    } = this.props;

    const heading = RESOURCES[resourceType].tableName;
    return (
      <React.Fragment>
        <div className="spacer"/>
        <Typography variant="h6">{heading}</Typography>
        <CreatableSelect
          isClearable
          isDisabled={isDisabled}
          isLoading={isLoading}
          onChange={this.handleChange}
          onCreateOption={this.handleCreate}
          value={this.valueFromResources()}
          options={this.options()}
        />
        {hasError && <div className="error">{errorMessage}</div>}
      </React.Fragment>
    );
  }
}

export default ResourceSelect;

ResourceSelect.propTypes = {
  resourceType: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  createResource: PropTypes.func.isRequired,
  resources: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  childResourceType: PropTypes.string,
  parentResourceType: PropTypes.string, // eslint-disable-line
  parentResourceId: PropTypes.string, // eslint-disable-line
};

ResourceSelect.defaultProps = {
  resources: {},
  value: undefined,
  isLoading: false,
  isDisabled: false,
  hasError: false,
  errorMessage: null,
  childResourceType: null,
  parentResourceType: null,
  parentResourceId: null,
};
