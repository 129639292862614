import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './store';
import { getResources } from './actions/airtable';
import Documents from './components/Documents';
import Form from './components/Form';
import './App.css';

const initialState = {};
const store = configureStore(initialState);

class App extends React.Component {
  componentDidMount() {
    const getResourceType = resourceType => (
      store.dispatch(getResources({ requestKey: `get-${resourceType}`, resourceType }))
    );

    // Pre-load the top level resources, we will fetch e.g. buyer Contacts once a buyer is selected
    getResourceType('contract');
    getResourceType('association');
    getResourceType('buyer');
    getResourceType('vendor');
  }

  render() {
    return (
      <Provider store={store}>
        <div className="app">
          <Documents />
          <hr className="divider" />
          <Form />
        </div>
      </Provider>
    );
  }
}

export default App;
