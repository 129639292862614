import { combineReducers } from 'redux';
import { resourceReducer } from 'redux-resource';

export default combineReducers({
  association: resourceReducer('association'),
  buyer: resourceReducer('buyer'),
  buyerContactInfo: resourceReducer('buyerContactInfo'),
  contract: resourceReducer('contract'),
  vendor: resourceReducer('vendor'),
  vendorContactInfo: resourceReducer('vendorContactInfo'),
});
