export const RESOURCES = {
  association: {
    tableName: 'Associations',
    fieldName: 'Associations',
  },
  buyer: {
    tableName: 'Buyers',
    fieldName: 'Buyer',
  },
  buyerContactInfo: {
    tableName: 'Buyer Contact Info',
    fieldName: 'Buyer contact',
  },
  contract: {
    tableName: 'Need Ingestion',
  },
  vendor: {
    tableName: 'Vendors',
    fieldName: 'Vendor Name',
  },
  vendorContactInfo: {
    tableName: 'Vendor Contact Info',
    fieldName: 'Vendor contact(s)',
  },
};

export const BUYER_TYPES = [
  { label: 'Local agency' },
  { label: 'State agency' },
  { label: 'Federal agency' },
  { label: 'National purchasing cooperative' },
  { label: 'Regional purchasing cooperative' },
  { label: 'School' },
  { label: 'Nonprofit' },
  { label: 'Other' },
];

export const STATE_OPTIONS = [
  { label: 'Alabama' },
  { label: 'Alaska' },
  { label: 'American Samoa' },
  { label: 'Arizona' },
  { label: 'Arkansas' },
  { label: 'California' },
  { label: 'Colorado' },
  { label: 'Connecticut' },
  { label: 'Delaware' },
  { label: 'District of Columbia' },
  { label: 'Florida' },
  { label: 'Georgia' },
  { label: 'Guam' },
  { label: 'Hawaii' },
  { label: 'Idaho' },
  { label: 'Illinois' },
  { label: 'Indiana' },
  { label: 'Iowa' },
  { label: 'Kansas' },
  { label: 'Kentucky' },
  { label: 'Louisiana' },
  { label: 'Maine' },
  { label: 'Maryland' },
  { label: 'Massachusetts' },
  { label: 'Michigan' },
  { label: 'Minnesota' },
  { label: 'Mississippi' },
  { label: 'Missouri' },
  { label: 'Montana' },
  { label: 'Nebraska' },
  { label: 'Nevada' },
  { label: 'New Hampshire' },
  { label: 'New Jersey' },
  { label: 'New Mexico' },
  { label: 'New York' },
  { label: 'North Carolina' },
  { label: 'North Dakota' },
  { label: 'Ohio' },
  { label: 'Oklahoma' },
  { label: 'Oregon' },
  { label: 'Pennsylvania' },
  { label: 'Puerto Rico' },
  { label: 'Rhode Island' },
  { label: 'South Carolina' },
  { label: 'South Dakota' },
  { label: 'Tennessee' },
  { label: 'Texas' },
  { label: 'U.S. Virgin Islands' },
  { label: 'Utah' },
  { label: 'Vermont' },
  { label: 'Virginia' },
  { label: 'Washington' },
  { label: 'West Virginia' },
  { label: 'Wisconsin' },
  { label: 'Wyoming' },
];
