import { connect } from 'react-redux';
import { getStatus } from 'redux-resource';
import { values } from 'lodash';
import Documents from './Documents';

const getRequestKey = 'get-contract';
const contractResourceType = 'contract';

const mapStateToProps = (state) => {
  const getContractStatus = getStatus(
    state,
    `${contractResourceType}.requests[${getRequestKey}].status`,
  );

  const contract = values(state[contractResourceType].resources)[0];

  return {
    contract,
    isLoading: getContractStatus.loading || getContractStatus.pending,
    hasError: getContractStatus.failed,
  };
};

export default connect(mapStateToProps)(Documents);
