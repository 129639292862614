import { connect } from 'react-redux';
import { getStatus } from 'redux-resource';
import { values } from 'lodash';
import { getResources, updateResource } from '../../actions/airtable';

import Form from './Form';

const getRequestKey = resourceType => `get-${resourceType}`;
const contractResourceType = 'contract';
const updateRequestKey = 'update-contract';

const mapStateToProps = (state) => {
  const updateContractStatus = getStatus(
    state,
    `${contractResourceType}.requests[${updateRequestKey}].status`,
  );
  const updateError = updateContractStatus.failed
    ? state[contractResourceType].requests[updateRequestKey].errorMessage
    : null;

  const contract = values(state[contractResourceType].resources)[0];

  return {
    contractId: contract && contract.id,
    isSubmitting: updateContractStatus.loading,
    hasError: updateContractStatus.failed,
    errorMessage: updateError,
  };
};

const mapDispatchToProps = dispatch => ({
  updateContract: ({ params, contractId, onSuccess }) => {
    dispatch(updateResource({
      requestKey: updateRequestKey,
      resourceType: contractResourceType,
      resourceId: contractId,
      params,
      onSuccess,
    }));
  },
  fetchParentSource: ({
    resourceType,
    parentResourceName,
    parentResourceType,
  }) => dispatch(getResources({
    requestKey: getRequestKey(resourceType),
    resourceType,
    parentResourceName,
    parentResourceType,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
