import { connect } from 'react-redux';
import { getStatus } from 'redux-resource';

import { createResource } from '../../actions/airtable';

import ResourceSelect from './ResourceSelect';


const getRequestKey = resourceType => `get-${resourceType}`;
const createRequestKey = resourceType => `create-${resourceType}`;

const mapStateToProps = (state, { resourceType }) => {
  const { resources } = state[resourceType];

  const fetchResourcesStatus = getStatus(
    state,
    `${resourceType}.requests[${getRequestKey(resourceType)}].status`,
  );

  const readError = fetchResourcesStatus.failed
    && state[resourceType].requests[getRequestKey(resourceType)].errorMessage;

  const createResourceStatus = getStatus(
    state,
    `${resourceType}.requests[${getRequestKey(resourceType)}].status`,
  );

  const createError = createResourceStatus.failed
    && state[resourceType].requests[getRequestKey(resourceType)].errorMessage;

  const defaultError = 'Something went wrong, please reload the page';

  return {
    resources,
    isDisabled: fetchResourcesStatus.idle,
    isLoading: fetchResourcesStatus.pending || createResourceStatus.pending,
    hasError: fetchResourcesStatus.failed || createResourceStatus.failed,
    errorMessage: readError || createError || defaultError,
  };
};

const mapDispatchToProps = (dispatch, {
  resourceType,
  parentResourceType,
  parentResourceId,
}) => ({
  createResource: (value, onSuccess) => {
    const params = {
      name: value,
      ...parentResourceId && { [parentResourceType]: [parentResourceId] },
    };
    return dispatch(createResource({
      requestKey: createRequestKey(resourceType),
      resourceType,
      params,
      onSuccess,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceSelect);
