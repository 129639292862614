import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { mapKeys } from 'lodash';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import { RESOURCES, BUYER_TYPES, STATE_OPTIONS } from '../../constants';
import ResourceSelect from '../ResourceSelect';
import ContactInfoForm from '../ContactInfoForm';

const initialState = {
  // Airtable requires foreign keys to be stored in Arrays
  association: [],
  buyer: [],
  buyerContactInfo: [],
  vendor: [],
  vendorContactInfo: [],
};

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  mapStateToFields = (values = {}) => {
    const returnValues = values;
    mapKeys(this.state, (value, key) => {
      const newKey = RESOURCES[key].fieldName;
      delete returnValues[key];
      returnValues[newKey] = value;
    });

    return returnValues;
  }

  initialFormikValues = () => ({
    ...this.mapStateToFields(),
    'Contract name': null,
    Summary: null,
    'Contract number': null,
    'Cooperative Contract?': null,
    'Effective Date': null,
    'Renewal Date': null,
    'Expiration Date': null,
    State: null,
    'Buyer Type': null,
    'Number of renewals': null,
    'Source URL': null,
    WMBE: null,
    'Sole Source': null,
    'Authorized agencies': null,
    'Administrative fee': null,
  });

  handleResourceSelection = ({ resourceType, selected, childResourceType }) => {
    if (childResourceType && selected && selected.label) {
      this.props.fetchParentSource({
        resourceType: childResourceType,
        parentResourceName: selected.label,
        parentResourceType: resourceType,
      });
    }

    // Clear resourceType value on un-select
    const value = (selected && [selected.value]) || [];
    this.setState({ [resourceType]: value });

    return value;
  }

  handleSubmit = (formikValues) => {
    return this.props.updateContract({
      params: this.mapStateToFields(formikValues),
      contractId: this.props.contractId,
      onSuccess: this.onCreate,
    });
  }

  onCreate = () => {
    // TODO
    alert('Contract saved!');
    window.location.reload();
  }

  render() {
    return (
      <Grid container spacing={24}>
        <Grid item xs={7}>
          <Formik
            initialValues={this.initialFormikValues()}
            onSubmit={this.handleSubmit}
            validateOnBlur={false}
            // validationSchema={validationSchema} TODO
          >
            {({
              values,
              submitForm,
              handleChange,
              setFieldValue,
            }) => (
              <form
                onSubmit={(e) => {
                  submitForm();
                  e.preventDefault();
                }}
              >
                <Typography variant="h4">Add metadata</Typography>
                {/* Contract Name */}
                <TextField
                  label="Contract Name"
                  name="Contract name"
                  onChange={handleChange}
                  defaultValue={values['Contract name']}
                  fullWidth
                />
                {/* Contract Summary */}
                <TextField
                  label="Contract Summary"
                  name="Summary"
                  onChange={handleChange}
                  defaultValue={values.Summary}
                  fullWidth
                  multiline
                />
                {/* Contract number */}
                <TextField
                  label="Contract Number"
                  name="Contract number"
                  onChange={handleChange}
                  defaultValue={values['Contract number']}
                  fullWidth
                />
                {/* Cooperative Contract? */}
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="Cooperative Contract?"
                      onChange={handleChange}
                      defaultValue={values['Cooperative Contract?']}
                    />
                  )}
                  label="Cooperative Contract?"
                />
                {/* Association */}
                <ResourceSelect
                  resourceType="association"
                  value={this.state.association[0]}
                  handleChange={(e) => {
                    const value = this.handleResourceSelection(e);
                    setFieldValue('association', value);
                  }}
                />
                {/* Buyer */}
                <ResourceSelect
                  resourceType="buyer"
                  value={this.state.buyer[0]}
                  childResourceType="buyerContactInfo"
                  handleChange={(e) => {
                    const value = this.handleResourceSelection(e);
                    setFieldValue('buyer', value);
                  }}
                />
                {/* Buyer Contact Info */}
                <ResourceSelect
                  resourceType="buyerContactInfo"
                  value={this.state.buyerContactInfo[0]}
                  parentResourceType="buyer"
                  parentResourceId={this.state.buyer[0]}
                  handleChange={(e) => {
                    const value = this.handleResourceSelection(e);
                    setFieldValue('buyerContactInfo', value);
                  }}
                />
                {/* State */}
                <div className="spacer" />
                <Typography variant="h6">State</Typography>
                <Select
                  options={STATE_OPTIONS}
                  name="State"
                  handleChange={handleChange}
                />
                {/* Buyer Type */}
                <div className="spacer" />
                <Typography variant="h6">Buyer Type</Typography>
                <Select
                  options={BUYER_TYPES}
                  name="Buyer Type"
                  handleChange={handleChange}
                />
                {/* Vendor */}
                <ResourceSelect
                  resourceType="vendor"
                  value={this.state.vendor[0]}
                  childResourceType="vendorContactInfo"
                  handleChange={(e) => {
                    const value = this.handleResourceSelection(e);
                    setFieldValue('vendor', value);
                  }}
                />
                {/* Vendor Contact Info */}
                <ResourceSelect
                  resourceType="vendorContactInfo"
                  value={this.state.vendorContactInfo[0]}
                  parentResourceType="vendor"
                  parentResourceId={this.state.vendor[0]}
                  handleChange={(e) => {
                    const value = this.handleResourceSelection(e);
                    setFieldValue('vendorContactInfo', value);
                  }}
                />
                {/* Effective Date */}
                <div className="spacer" />
                <TextField
                  label="Effective Date"
                  type="date"
                  name="Effective Date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  defaultValue={values['Effective Date']}
                />
                {/* Renewal Date */}
                <div className="spacer" />
                <TextField
                  label="Renewal Date"
                  type="date"
                  name="Renewal Date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  defaultValue={values['Renewal Date']}
                />
                {/* Expiration Date */}
                <div className="spacer" />
                <TextField
                  label="Expiration Date"
                  type="date"
                  name="Expiration Date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  defaultValue={values['Expiration Date']}
                />
                {/* Number of renewals */}
                <div />
                <TextField
                  label="Number of Renewals"
                  type="number"
                  name=""
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
                {/* Source URL */}
                <TextField
                  label="Source URL"
                  name="Source URL"
                  onChange={handleChange}
                  defaultValue={values['Source URL']}
                  fullWidth
                />
                {/* WMBE */}
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="WMBE"
                      onChange={handleChange}
                      defaultValue={values.WMBE}
                    />
                  )}
                  label="Woman or minority owned business?"
                />
                {/* Sole Source */}
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="Sole Source"
                      onChange={handleChange}
                      defaultValue={values['Sole Source']}
                    />
                  )}
                  label="Sole Source?"
                />
                {/* Authorized agencies */}
                <TextField
                  label="Authorized Agencies"
                  name="Authorized agencies"
                  onChange={handleChange}
                  defaultValue={values['Authorized agencies']}
                  fullWidth
                />
                {/* Administrative fee */}
                <TextField
                  label="Administrative Fee"
                  name="Administrative fee"
                  onChange={handleChange}
                  defaultValue={values['Administrative fee']}
                />
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={this.props.isSubmitting}
                    style={{ margin: '1rem 0 0 0' }}
                  >
                    Submit
                  </Button>
                </div>
                {this.props.errorMessage && (
                  <div className="error">{this.props.errorMessage}</div>
                )}
              </form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={5}>
          <ContactInfoForm
            contactType="buyerContactInfo"
            resourceType="buyer"
            contactId={this.state.buyerContactInfo[0]}
            resourceId={this.state.buyer[0]}
          />
          <ContactInfoForm
            contactType="vendorContactInfo"
            resourceType="vendor"
            contactId={this.state.vendorContactInfo[0]}
            resourceId={this.state.vendor[0]}
          />
        </Grid>
      </Grid>
    );
  }
}

export default Form;

Form.propTypes = {
  contractId: PropTypes.string, // eslint-disable-line
  fetchParentSource: PropTypes.func.isRequired,
  updateContract: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Form.defaultProps = {
  contractId: undefined,
  isSubmitting: false,
  hasError: false,
  errorMessage: null,
};
