import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { RESOURCES } from '../../constants';

class ContactInfoForm extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.contactId !== this.props.contactId) {
      this.forceUpdate();
    }
  }

  render() {
    const {
      contactType,
      contactId,
      contact,
      resourceType,
      resourceId,
      onSubmit,
      isLoading,
    } = this.props;
    if (!resourceId || !contactId) return null;

    return (
      <Formik
        enableReinitialize
        initialValues={{
          name: contact.name || '',
          email: contact.email || '',
          phone: contact.phone || '',
          ...contact.zipcode && { zipcode: contact.zipcode || '' },
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        // validationSchema={validationSchema} TODO
      >
        {({ values, submitForm, handleChange }) => {
          const heading = RESOURCES[contactType].tableName;
          return (
            <form
              onSubmit={(e) => {
                submitForm();
                e.preventDefault();
              }}
            >
              <Typography variant="h6">{heading}</Typography>
              <TextField
                id="name"
                label="Name"
                onChange={handleChange}
                margin="normal"
                value={values.name}
                fullWidth
              />

              {/* Add validation */}
              <TextField
                id="email"
                label="Email Address"
                onChange={handleChange}
                margin="normal"
                value={values.email}
                fullWidth
              />

              {/* Add mask */}
              <TextField
                id="phone"
                label="Phone Number"
                onChange={handleChange}
                margin="normal"
                value={values.phone}
                fullWidth
              />

              {/* Only for Vendors */}
              {resourceType === 'vendor' && (
                <TextField
                  id="zipcode"
                  label="Zipcode"
                  onChange={handleChange}
                  margin="normal"
                  value={values.zipcode}
                />
              )}
              <div />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                Update Contact
              </Button>
              <div className="spacer" />
            </form>
          );
        }
      }
      </Formik>
    );
  }
}

export default ContactInfoForm;

ContactInfoForm.propTypes = {
  contactType: PropTypes.string,
  contactId: PropTypes.string, // eslint-disable-line
  contact: PropTypes.shape({}),
  resourceType: PropTypes.string,
  resourceId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ContactInfoForm.defaultProps = {
  contactType: undefined,
  contactId: undefined,
  contact: {},
  resourceType: undefined,
  resourceId: undefined,
  isLoading: false,
};
