import { connect } from 'react-redux';
import { getStatus } from 'redux-resource';
import { updateResource } from '../../actions/airtable';

import ContactInfoForm from './ContactInfoForm';


const getRequestKey = resourceType => `get-${resourceType}`;
const updateRequestKey = contactType => `update-${contactType}`;

const mapStateToProps = (state, { contactType, resourceType, contactId }) => {
  const getResourcesStatus = getStatus(
    state,
    `${resourceType}.requests[${getRequestKey(resourceType)}].status`,
    true, // treatIdleAsPending
  );

  const updateResourceStatus = getStatus(
    state,
    `${contactType}.requests[${updateRequestKey(contactType)}].status`,
  );

  const contact = contactId && state[contactType].resources[contactId].fields;

  return {
    contact,
    isLoading: getResourcesStatus.pending || updateResourceStatus.pending,
    hasError: getResourcesStatus.failed || updateResourceStatus.failed,
  };
};

const mapDispatchToProps = (dispatch, {
  contactType,
  contactId,
  resourceId,
}) => ({
  onSubmit: (formikValues, formikActions) => {
    // Reset status to clear apiErrors.
    formikActions.setStatus();

    // TODO Handle API errors
    return dispatch(updateResource({
      requestKey: updateRequestKey(contactType),
      resourceType: contactType,
      resourceId: contactId,
      parentResourceId: resourceId,
      params: formikValues,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoForm);
