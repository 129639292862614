import Airtable from 'airtable';
import { actionTypes } from 'redux-resource';
import { uniqueId } from 'lodash';
import { RESOURCES } from '../constants';

// Initialize Airtable
const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;

const baseId = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_AIRTABLE_PRODUCTION_BASE
  : process.env.REACT_APP_AIRTABLE_DEVELOPMENT_BASE;

const base = new Airtable({ apiKey }).base(baseId);

const fields = {
  association: ['name'],
  buyer: ['name'],
  buyerContactInfo: ['buyer', 'name', 'email', 'phone'],
  contract: [
    'Contract name', 'Contract', 'Bid tabulation',
    'Amendments and modifications', 'Bid solicitation',
    'Other documents', 'Pricing',
  ],
  vendor: ['name'],
  vendorContactInfo: ['vendor', 'name', 'email', 'phone', 'zipcode'],
};


export function getResources({
  requestKey,
  resourceType,
  parentResourceName,
  parentResourceType,
}) {
  return (dispatch) => {
    // Set request to pending
    dispatch({
      type: actionTypes.READ_RESOURCES_PENDING,
      resourceType,
      requestKey,
    });

    // If we are getting the next blank contract, limit to one record with a blank
    // 'Contract name'. If the record has a parentResource, make sure the id matches.
    const shouldFilter = resourceType === 'contract' || parentResourceName;
    const filterByFormula = (
      resourceType === 'contract'
        ? "{Contract name}=''"
        : `${parentResourceType} = "${parentResourceName}"`
    );
    const maxRecords = (resourceType === 'contract' ? 1 : 10000);

    const opts = {
      view: 'Grid view',
      maxRecords,
      fields: fields[resourceType],
      ...shouldFilter && { filterByFormula },
    };
    const { tableName } = RESOURCES[resourceType];

    base(tableName).select(opts).eachPage((records, fetchNextPage) => {
      // eachPage accepts two functions as arguments
      // 1) 'page' which gets a set of records from airtable and a fetchNextPage
      //    function which can be called to fetch any paginated results
      // 2) 'done' which will be called when all items are returned or Airtable
      //    hits an error. The err param will be populated when an error occurs.

      // Add records to the redux store
      dispatch({
        type: actionTypes.READ_RESOURCES_SUCCEEDED,
        resourceType,
        requestKey: uniqueId(requestKey),
        resources: records,
      });

      // To fetch the next page of records, call `fetchNextPage`.
      // If there are more records, `page` will get called again.
      // If there are no more records, `done` will get called.
      fetchNextPage();
    }, (err) => {
      if (err) {
        // Set request to failed
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          resourceType,
          requestKey,
          requestProperties: {
            statusCode: err && err.statusCode,
            errorMessage: err && err.message,
          },
        });
        return Promise.reject(err);
      }

      // Set the overall request to succeeded
      dispatch({
        type: actionTypes.READ_RESOURCES_SUCCEEDED,
        resourceType,
        requestKey,
        resources: [],
      });

      return Promise.resolve();
    });
  };
}

export function createResource({
  requestKey,
  resourceType,
  params,
  onSuccess,
}) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_RESOURCES_PENDING,
      resourceType,
      requestKey,
    });

    const { tableName } = RESOURCES[resourceType];
    base(tableName).create(params, (err, record) => {
      if (err) {
        dispatch({
          type: actionTypes.CREATE_RESOURCES_FAILED,
          resourceType,
          requestKey,
          requestProperties: {
            statusCode: err && err.statusCode,
            errorMessage: err && err.message,
          },
        });

        return Promise.reject(err);
      }

      dispatch({
        type: actionTypes.CREATE_RESOURCES_SUCCEEDED,
        resourceType,
        requestKey,
        resources: [record],
      });

      if (onSuccess) return onSuccess(record);

      return null;
    });
  };
}

export function updateResource({
  requestKey,
  resourceType,
  resourceId,
  params,
  onSuccess,
}) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_RESOURCES_PENDING,
      resourceType,
      requestKey,
      resources: [resourceId],
    });

    const { tableName } = RESOURCES[resourceType];
    base(tableName).update(resourceId, params, (err, record) => {
      if (err) {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_FAILED,
          resourceType,
          resources: [resourceId],
          requestKey,
          requestProperties: {
            statusCode: err && err.statusCode,
            errorMessage: err && err.message,
          },
        });
        return Promise.reject(err);
      }

      dispatch({
        type: actionTypes.UPDATE_RESOURCES_SUCCEEDED,
        resourceType,
        requestKey,
        resources: [record],
      });

      if (onSuccess) return onSuccess(record);

      return null;
    });
  };
}
